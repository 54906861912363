import React, { useState } from 'react';
import creditCardImage from './images/credit-card.png';

const TotalCost = ({ cost, numInfluencers, token_string, goals, selectedPlatforms, selectedSizes, emailName, discount, promoCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const discountedCost = discount ? (cost * (1 - discount)).toFixed(2) : cost.toFixed(2);
  const finalCost = (Math.max(cost, 0)).toFixed(2);

  // Validation function before sending payload
  const validateBeforeCheckout = () => {
    // Ensure at least one platform is selected
    if (selectedPlatforms.length === 0) {
      alert('Please select at least one platform.');
      return false;
    }

    // Ensure at least one influencer size is true
    const hasValidSize = Object.values(selectedSizes).some(value => value === true);
    if (!hasValidSize) {
      alert('Please select at least one influencer size.');
      return false;
    }

    // Ensure outreach goals have been selected
    if (!goals || goals.length === 0) {
      alert('Please select your outreach goals.');
      return false;
    }

    // All validations passed
    return true;
  };

  const handleCheckout = async () => {
    // Check validations before proceeding
    if (!validateBeforeCheckout()) return;

    setIsLoading(true); // Start loading
    const payload = {
      token: token_string,
      amount: numInfluencers,
      selectedOptions: goals,
      emailSignatureName: emailName,
      selectedPlatforms: { "TikTok": selectedPlatforms.includes('TikTok'), "YouTube": selectedPlatforms.includes('YouTube') },
      selectedSizes: selectedSizes,
      promoCode: promoCode,
      successUrl: "https://confirmation.jobs.chat"
    };

    try {
      console.log(payload);
      const response = await fetch('https://kz45kpecvgh5bwewvhicbsfpmu0vyzkr.lambda-url.us-east-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      
      const data = await response.json();

      if (response.ok && data.checkoutUrl) {
        window.location.href = data.checkoutUrl; // Redirect
      } else {
        console.error('Checkout failed:', data);
        alert('There was an error processing your request. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching:', error);
      alert('There was an error processing your request. Please try again.');
    } finally {
      setIsLoading(false); // End loading regardless of the outcome
    }
  };

  return (
    <div className="outerccdiv">
      <div className="innerccdiv">
      <h3 className="heading-6">
          Total Cost:
          {discount > 0 ? (
            <>
            {' '}
              <span style={{textDecoration: 'line-through', color: '#d9534f', fontWeight: 'normal' }}>${finalCost}</span>
              {' '}
              <span style={{ color: '#5cb85c' }}>${discountedCost}</span>
            </>
          ) : (
            <>
            {' '}
            <span>${finalCost}</span>
            </>
          )}
        </h3>
        <img src={creditCardImage} alt="Credit Card" style={{ height: '27px', marginTop:'-3px' }} />
      </div>
      <p className="paragraph-3">
        Proceed securely with Stripe. Our AI agent, backed by human guarantee, ensures quality results. If the AI agent is unable to find the number of suitable influencers you requested or if you are unsatisfied with the results, we offer a money-back guarantee upon review.
      </p>
      <div className="checkout-b-div">
  {isLoading ? (
    <button disabled className="checkout-button w-button">
      <span style={{ flex: 1, textAlign: 'center' }}>Processing...</span>
      <div className="spinner" style={{ position: 'absolute', right: '13px', bottom:'11px' }}></div>
    </button>
  ) : (
    <button onClick={handleCheckout} className="checkout-button w-button">Proceed to Checkout</button>
  )}
</div>

    </div>
  );
};

export default TotalCost;
