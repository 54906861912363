import React from 'react';
import tikTokImage from './images/tiktok.svg';
import youtubeImage from './images/youtube.svg';

function PlatformSelector({ selectedPlatforms, onPlatformToggle }) {
  // Inline style for the "Coming Soon" text
  const comingSoonStyle = {
    fontSize: '0.7em',
    color: '#00BFFF',
    marginTop: '-2px', // Control spacing between the logo and text
  };

  // Inline style for making an item appear disabled and for the container of YouTube
  const youtubeStyle = {
    pointerEvents: 'none', // Disables clicking
    opacity: '0.5', // Visually indicates it's not selectable
    display: 'flex', // Use flexbox for layout
    flexDirection: 'column', // Stack children vertically
    alignItems: 'center', // Center items horizontally
  };

  // Function to handle click events
  const handleClick = (platform) => {
    if (platform === 'YouTube') return; // Ignore clicks for YouTube
    onPlatformToggle(platform);
  };

  
  return (
    <div className="div-block">
      <div
        key="TikTok"
        className={`link-block w-inline-block platform-tiktok ${selectedPlatforms['TikTok'] ? `selected-tiktok` : ''}`}
        onClick={() => handleClick('TikTok')}
        role="button"
        tabIndex="0"
      >
        <img src={tikTokImage} loading="lazy" alt="TikTok" className="image" />
        <h5 className="heading-5">TikTok</h5>
      </div>

      <div
        key="YouTube"
        className="link-block w-inline-block platform-youtube"
        style={youtubeStyle} // Use the YouTube-specific styles
      >
        <div style={{ display: 'flex', alignItems: 'center', padding: '0', gap: '5px' }}>
        <img src={youtubeImage} loading="lazy" alt="YouTube" className="image" />
       
        <h5 className="heading-5"style={{  padding: '0', margin: '0' }}>Youtube</h5>
        </div>
        <div style={comingSoonStyle}>(Coming Soon)</div>
      </div>
    </div>
  );
}

export default PlatformSelector;
