import React, { useState, useEffect } from 'react';
import Slider from './Slider';
import PlatformSelector from './PlatformSelector';

function MathComponent({ onSliderChange, onPlatformChange, selections, onPriceChange }) {
    const [sliderValue, setSliderValue] = useState(0);
    const [selectedPlatforms, setSelectedPlatforms] = useState({
        TikTok: true,
        YouTube: false,
    });

    // Update slider value based on platform selection and slider movement
    useEffect(() => {
        const influencerTypesCount = Object.values(selections).filter(isSelected => isSelected).length;

        //const t_max = influencerTypesCount === 0 ? 120 : influencerTypesCount * 60;
        let t_max = 200;

        if (selections.macro && selections.micro) {
            t_max = 200;
        } else if (selections.macro) {
            t_max = 60;
        } else if (selections.micro) {
            t_max = 120;
        }

        const platformCount = Object.values(selectedPlatforms).filter(value => value).length;
        const maxSliderValue = platformCount === 2 ? 2*t_max : t_max;
        //console.log(influencerTypesCount)
        // Adjust slider value if it exceeds the maximum allowed value for current platform selection
        if (sliderValue > maxSliderValue) {
            setSliderValue(maxSliderValue);
            onSliderChange(maxSliderValue);
        }
/*
        const minSliderValue = selectedPlatformCount === 0 ? 0 // If no platform is selected, max is 0
                : selectedPlatformCount === 1 ? 10 // If 1 platform is selected, max is 100
                : 20;*/
        //const minSliderValue = maxSliderValue / 6;
        const minSliderValue = 20;
        if (sliderValue < minSliderValue) {
                    setSliderValue(minSliderValue);
                    onSliderChange(minSliderValue);
                }
    }, [selections, selectedPlatforms, sliderValue, onSliderChange]);

    const handleSliderChange = (value) => {
        setSliderValue(value);
        onSliderChange(value);
    };

    const handlePlatformSelection = (platform) => {
        const updatedPlatforms = {
            ...selectedPlatforms,
            [platform]: !selectedPlatforms[platform],
        };
        setSelectedPlatforms(updatedPlatforms);
    
        // Convert updatedPlatforms object to an array of selected platform names
        const selectedPlatformNames = Object.entries(updatedPlatforms)
            .filter(([key, value]) => value) // Filter out unselected platforms
            .map(([key]) => key); // Extract the platform name
    
        // Reset the slider if no platforms are selected, adjust for 1 or 2 platforms as before
        const platformCount = selectedPlatformNames.length;
        if (platformCount === 0) {
            //setSliderValue(0);
            //onSliderChange(0);
        } else if (platformCount === 1 && (sliderValue < 10 || sliderValue === 0)) {
            setSliderValue(10);
            onSliderChange(10);
        } else if (platformCount === 2 && (sliderValue < 20 || sliderValue === 0)) {
            setSliderValue(20);
            onSliderChange(20);
        }
    
        onPlatformChange(selectedPlatformNames); // Now sends back an array of selected platform names
    };

    // Compute the price
    useEffect(() => {
        let price = sliderValue * 2.5;
        onPriceChange(price);
    }, [sliderValue, onPriceChange]);

    const influencerTypesCount = Object.values(selections).filter(isSelected => isSelected).length;

    let t_max = 200;

    if (selections.macro && selections.micro) {
        t_max = 200;
    } else if (selections.macro) {
        t_max = 60;
    } else if (selections.micro) {
        t_max = 120;
    }
    //const t_max = influencerTypesCount === 0 ? 120 : influencerTypesCount * 60;
    const selectedPlatformCount = Object.values(selectedPlatforms).filter(value => value).length;
    const sliderMax = selectedPlatformCount === 0 ? t_max // If no platform is selected, max is 0
                : selectedPlatformCount === 1 ? t_max // If 1 platform is selected, max is 100
                : t_max*2;
    /*
    const sliderMin = selectedPlatformCount === 0 ? 0 // If no platform is selected, max is 0
                : selectedPlatformCount === 1 ? 10 // If 1 platform is selected, max is 100
                : 20;
    */
    //const sliderMin = sliderMax / 6;
    const sliderMin = 20;
    return (
        <div>
            <div className="platform-selection-div">
                <h2 className="heading-4">Platform Selection</h2>
                <p className="paragraph-2">Choose between TikTok and YouTube as the desired platforms for your influencer marketing campaign.</p>
                <PlatformSelector selectedPlatforms={selectedPlatforms} onPlatformToggle={handlePlatformSelection}/>
                <p className="platform-warning" style={{visibility: selectedPlatformCount ? 'hidden' : 'visible'}}>Please select a platform.</p>
            </div>
            <div className="number-of-influencers-div">
                <h2 className="heading-4">Number of Influencers</h2>
                <p className="paragraph-2">Use the slider to select the desired number of influencers for your marketing package.</p>
                <Slider value={sliderValue} onValueChange={handleSliderChange} sliderMin={sliderMin} sliderMax={sliderMax}/>
            </div>
        </div>
    );
}

export default MathComponent;
