import React, { useState } from 'react';

function DropdownMenu({ onSelectValue }) {
  // State to track if the default option is selected
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);

  const handleChange = (event) => {
    onSelectValue(event.target.value);
    // Update the state based on whether the default option is selected
    setIsDefaultSelected(event.target.value === "");
  };

  return (
    <div className="dropdown-menu-container">
      <select
        id="outreach-goals"
        // Apply different class based on isDefaultSelected
        className={`form-select ${isDefaultSelected ? "default-selected" : "option-selected"}`}
        aria-label="Default select example"
        defaultValue=""
        onChange={handleChange}
      >
        <option value="" disabled>Select an outreach goal</option>
        <option value="general">General Pitch</option>
        <option value="paid">Paid Promo</option>
        <option value="affiliate">Affiliate</option>
        <option value="ugc-no-post">UGC, No Post</option>
      </select>
    </div>
  );
}

export default DropdownMenu;
