import React from 'react';

function TextInput({ onNameChange }) {
  return (
    <div className="input-container">
      <input
        type="text"
        id="email-signature-name"
        className="form-input"
        placeholder="Emails written on behalf of:"
        onChange={(event) => onNameChange(event.target.value)}
      />
    </div>
  );
}

export default TextInput;
