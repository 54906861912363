// EditableList.js
import React from 'react';
import EditableRow from './EditableRow'; // Adjust the import path as necessary

const EditableList = ({ items, maxRows }) => {
  let displayedRows = Math.min(maxRows, items.length);
  let listWithDividers = [];

  if(maxRows == 0)
  return (
    <div className="editable-list">
      <div className="editable-row2">
      <span className="editable-text2">
        {"Please select a platform to view changes"}
      </span>
    </div>
    </div>
  );

  for (let i = 0; i < displayedRows; i++) {
    listWithDividers.push(
      <div className="editable-row-container" key={`container-${i}`}>
        <EditableRow key={`row-${i}`} text={items[i]} />
        {i < displayedRows - 1 && <div className="row-divider" />}
      </div>
    );
  }

  return (
    <div className="editable-list">
      {listWithDividers}
    </div>
  );
};

export default EditableList;