import React from 'react';

const EditableRow = ({ text }) => {
  return (
    <div className="editable-row">
      <span className="editable-text">
        {text}
      </span>
    </div>
  );
};

export default EditableRow;
