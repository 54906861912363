import React, { useState } from 'react';

function SelectableBoxes({ onSelectionChange }) {
  const [selections, setSelections] = useState({
    //nano: true,
    macro: true,
    micro: true,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const updatedSelections = { ...selections, [name]: checked };
    setSelections(updatedSelections);
    onSelectionChange(updatedSelections); // Assuming this prop function is for updating the parent component state
  };

  /*
   <input
        type="checkbox"
        id="nano"
        name="nano"
        className="custom-checkbox"
        checked={selections.nano}
        onChange={handleChange}
      />
      <label htmlFor="nano" className="checkbox-label">Nano (100-7.5K views)</label>
  */
  return (
    <div className="checkbox-container">
     

      <input
        type="checkbox"
        id="micro"
        name="micro"
        className="custom-checkbox"
        checked={selections.micro}
        onChange={handleChange}
      />
      <label htmlFor="micro" className="checkbox-label">Micro (average 100-25K views)</label>

      <input
        type="checkbox"
        id="macro"
        name="macro"
        className="custom-checkbox"
        checked={selections.macro}
        onChange={handleChange}
      />
      <label htmlFor="macro" className="checkbox-label" style={{ marginLeft: '40px' }}>Macro (average 25K+ views)</label>

      
    </div>
  );
}

export default SelectableBoxes;
