import { useState, useEffect } from 'react';
//const queryParams = new URLSearchParams(window.location.search);
//const ssID = queryParams.get('SessionID');
//const uID = queryParams.get('UserID');
const token = window.location.pathname.substring(1);
const useTargetAudienceInfo = () => {
  const [targetAudienceInfo, setTargetAudienceInfo] = useState([]);
  const [descriptionInfo, setDescriptionInfo] = useState([]);
  const [combinedInfo, setCombinedInfo] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const lambdaUrl = 'https://7zatd2ompjqcf2xmeaipq4ybru0hsrxs.lambda-url.us-east-2.on.aws/';
      //const sessionID = ssID;
      //const userID = uID;

      try {
        const response = await fetch(lambdaUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          //body: JSON.stringify({ SessionID: sessionID, UserID: userID }),
          body: JSON.stringify({ Token : token}),
        });
        console.log("fetch content");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        
        // Access 'TargetAudienceInfo' layer and extract the summaries
        const audienceStrings = Object.keys(data.TargetAudienceInfo).map(key => data.TargetAudienceInfo[key].target_audience_summary);

const descriptionStrings = Object.keys(data.Description).map(key => data.Description[key].description);


        setTargetAudienceInfo(audienceStrings);
        setDescriptionInfo(descriptionStrings);

        let totalStrings = [];
        for(let i = 0; i < Math.min(audienceStrings.length, descriptionStrings.length); i++)
        {
          totalStrings.push(audienceStrings[i]);
          totalStrings.push(descriptionStrings[i]);
        }
        setCombinedInfo(totalStrings);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  return { combinedInfo,error };
};

export default useTargetAudienceInfo;

