import React from 'react';

function PromoInput({ onNameChange, promoStatus, loading }) {
  return (
    <div className="input-container" style={{ position: 'relative' }}>
      <input
        type="text"
        id="promo-text"
        className="form-input2"
        placeholder="Enter code here"
        onChange={(event) => onNameChange(event.target.value)}
      />
      {loading && <div className="spinner2" style={{ position: 'absolute', right: '15px', bottom: '13px' }}></div>}
      {!loading && promoStatus && (
        <span className="promo-status" style={{  position: 'absolute', right: '15px', transform: 'translateY(-5px)', color: promoStatus === 'valid' ? 'green' : 'red' }}>
          {promoStatus}
        </span>
      )}
    </div>
  );
}

export default PromoInput;
