import React from 'react';

function Slider({value, onValueChange, sliderMin, sliderMax}) {

    const handleChange = (event) => {
        onValueChange(Math.max(parseInt(event.target.value, 10),sliderMin));
    };

    const percentage = sliderMax !== 0 ? (value / sliderMax) * 100 : 0;

    return (
        <div className="slider-container">
            <input
                type="range"
                max={sliderMax}
                min="0"
                value={value}
                onChange={handleChange}
                className="slider"
                style={{
                    background: `linear-gradient(to right, #007bff ${percentage}%, #d3d3d3 ${percentage}%)`
                }}
            />
            <p className="no-bottom-margin">Influencers: {value}</p>

        </div>
    );
}

export default Slider;